.listContainer{
    height: 100vh;
    background-color: rgba(240, 242, 245, 1);
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}
.listContainer .listCard{
    padding: 10px;
    text-align: left;
    background-color: white;
    border-radius: 20px;
    -webkit-user-select:none;
    user-select:none;
}
.listContainer .listCard .adm-card-header-title{
    flex: 1;
}
.listContainer .main{
    flex:1;
    height: 0;
    overflow: auto;
}
