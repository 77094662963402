.top-bar .adm-space {
    height: 100%;
    width: 100%;
}
.adm-notice-bar {
    --height: 30px;
}
.adm-notice-bar.adm-notice-bar-alert {
    --background-color: #F9FBFF;
    --border-color: none;
    --text-color: #03915d;
}
.chat-container .adm-list-item,
.chat-container .adm-list-item-content,
.chat-container .adm-list-item-content-main {
    max-width: inherit;
    display: block;
}
.container {
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 620px;
    height: 100vh;
    text-align: left;
    background: #F9FBFF;
}

.messages {
    position: absolute;
    background: #F9FBFF;
    opacity: 0.5;
    width: 30%;
    height: 70%;
    top: 2.5%;
    left: 5%;
    border-radius: 10px 0 0 10px;
    box-shadow: -5px 5px 10px rgba(119, 119, 119, 0.5);
}

.chatbox {
    position: absolute;
    /* left: 35%; */
    height: 100%;
    width: 100%;
    border-radius: 10px;
    box-shadow: 5px 5px 15px rgb(119 119 119 / 50%);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
    max-width: inherit;
}

.top-bar {
    width: 100%;
    height: 60px;
    background: #F9FBFF;
    border-radius: 10px 10px 0 0;
    position: fixed;
    top: 0;
    z-index: 9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    box-sizing: border-box;
    max-width: inherit;
}
.notice {
    position: fixed;
    top: 60px;
    height: 30px;
    width: 100%;
    z-index: 9;
    max-width: inherit;
    overflow: hidden;
    --height: 30px;
}

.avatar {
    width: 35px;
    height: 35px;
    background: linear-gradient(to bottom left, #79C7C5 20%, #A1E2D9 100%);
    border-radius: 50%;
    /* position: absolute; */
    top: 11px;
    left: 15px;
    overflow: hidden;
}

.avatar p {
    color: #F9FBFF;
    margin: 7px 12px;
}

.avatar img,svg {
    height: 100%;
    width: auto;
}

.name {
    /* position: absolute; */
    top: 22px;
    text-transform: uppercase;
    color: #777777;
    font-size: 0.8em;
    letter-spacing: 2px;
    font-weight: 500;
    left: 60px;
    flex: 1 1 auto;
    left: 60px;
    text-align: center;
}

.count {
    color: #777777;
    font-size: 0.8em;
    letter-spacing: 2px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 auto;
}

.count span {
    color: #19c37d;
    font-weight: bolder;
    font-size: 0.9rem;
}

.menu {
    /* position: absolute; */
    right: 10px;
    top: 20px;
    width: 56px;
    height: 20px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

.menu:hover {
    transform: scale(1.1);
    transition: all 0.3s ease-in;
}

.icons {
    position: absolute;
    color: #A1E2D9;
    padding: 10px;
    top: 5px;
    right: 21px;
    cursor: pointer;
}

.icons .fas {
    padding: 5px;
    opacity: 0.8;
}

.icons .fas:hover {
    transform: scale(1.1);
    transition: all 0.3s ease-in;
}

.dots {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #79C7C5;
    box-shadow: 0px 7px 0px #79C7C5, 0px 14px 0px #79C7C5;
    margin: 0 auto;
}

.middle {
    background: #F9FBFF;
    width: 100%;
    opacity: 1;
    margin-top: 60px;
    padding-bottom: 70px;
    flex: 1 1 auto;
    max-width: inherit;
}
.chat-container {
    display: flex;
    flex-direction: column;
    padding: 12px;
    --align-items: center;
    max-width: inherit;
}

.chat-bottom-line {
    height: 0;
    border-top: 1px solid #80808047;
    margin: 2px 0 4px;
}

/* .incoming {
    position: absolute;
    width: 50%;
    height: 100%;
    padding: 20px;
} */

.incoming.bubble {
    background: #b2b2b2;
}

.typing {
    position: absolute;
    right: 10px;
    top: 0px;
}

.typing .bubble {
    background: #eaeaea;
    padding: 8px 13px 9px 13px;
}

.ellipsis {
    width: 5px;
    height: 5px;
    display: inline-block;
    background: #b7b7b7;
    border-radius: 50%;
    animation: bounce 1.3s linear infinite;
}

.one {
    animation-delay: 0.6s;
}

.two {
    animation-delay: 0.5s;
}

.three {
    animation-delay: 0.8s;
}

.talking-item {
    position: relative;
    display: inline-block;
    width: fit-content;
    box-sizing: border-box;
    max-width: calc(100% - 30px);
    margin: 5px;
    font-size: 0.7em;
    padding: 10px 10px 10px 12px;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #19c37d;
    box-shadow: 0 0 3px 2px #9e9e9e75;
}
.talking-avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    overflow: hidden;
    margin-left: 0;
    margin-right: 5px;
}

.talking-avatar img {
    height: 100%;
    width: auto;
}

.talking-item-btns {
    padding: 4px 4px 0;
    display: flex;
    flex-direction: row;
}
.talking-item-btns > svg, .talking-item-btn {
    width: 16px;
    height: 16px;
    display: block;
    margin: 0px 8px;
    color: rgb(18, 150, 219);
}
.delete-btn {
    width: 16px;
    height: 16px;
    display: block;
    margin: 6px 8px;
}

.bubble {
    position: relative;
    display: inline-block;
    width: fit-content;
    margin: 5px;
    color: #F9FBFF;
    text-align: left;
    font-size: 14px;
    overflow-x: scroll;
}
.bubble::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;  /* IE and Edge */
  }


.lower {
    margin-top: 45px;
}

/* .outgoing {
    position: absolute;
    padding: 20px;
    right: 0;
    top: 15%;
    width: 50%;
    height: 100%;
} */

.outgoing.talking-item {
    background: #79C7C5;
    float: right;
    text-align: right;
    align-self: flex-end;
    flex-direction: row-reverse;
}
.outgoing .talking-avatar {
    margin-right: 0;
    margin-left: 5px;
}

.incoming.talking-item {
    background: #b2b2b2;
}

.bottom-bar {
    position: fixed;
    bottom: 0;
    width: 100%;
    /* height: 55px; */
    bottom: 0;
    background: #F9FBFF;
    border-radius: 0 0 10px 10px;
    z-index: 9;
    max-width: inherit;
}

.chat {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 8px 12px;
    box-sizing: border-box;
    position: relative;
}

.left {
    left: 0px;
}

input {
    padding: 7px;
    width: 74%;
    left: 5%;
    border: 0;
    top: 13px;
    background: #F9FBFF;
    color: #79C7C5;
    flex: 1 1 auto;
    box-sizing: border-box;
    font-size: 16px;
}

input::placeholder {
    color: #A1E2D9;
}

input:focus {
    color: #777777;
    outline: 0;
}
.button-container {
    position: relative;
}

.button-container .button {
    border: 0;
    font-size: 1em;
    color: #A1E2D9;
    opacity: 0.8;
    cursor: pointer;
    outline: 0;
    flex: 0 0 auto;
    background-color: #ffffff;
    box-shadow: 0 0 1px 1px #a1e2d9b8;
}

.button-container .button:hover {
    transform: scale(1.1);
    transition: all 0.3s ease-in-out;
    color: #79C7C5;
}

footer {
    position: absolute;
    bottom: 0;
    right: 0;
    text-align: center;
    font-size: 0.7em;
    padding: 10px;
}

footer p {
    color: #79C7C5;
    text-transform: uppercase;
    letter-spacing: 2px;
}

footer a {
    color: #F9FBFF;
    text-decoration: none;
}

footer a:hover {
    color: #A1E2D9;
}

.cancel-container {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    background: #f8f8f8;
}

.cancel-container .cancel {
    background: #b2b2b2;
    border: none;
    width: 100px;
    box-shadow: 0 0 3px 2px #9e9e9e75;
    opacity: 0.9;
    font-size: .875rem;
    line-height: 1.25rem;
    padding: 0.5rem 0.75rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 4px;
    color: rgb(18, 150, 219);
}

@keyframes bounce {
    30% {
        transform: translateY(-2px);
    }

    60% {
        transform: translateY(0px);
    }

    80% {
        transform: translateY(2px);
    }

    100% {
        transform: translateY(0px);
        opacity: 0.5;
    }
}

.codebox-handler {
    --tw-text-opacity: 1;
    color: rgba(217, 217, 227, var(--tw-text-opacity));
    padding: 0 10px;
    border-top-right-radius:5px;
    border-top-left-radius:5px;
    line-height: 32px;

}

